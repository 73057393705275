import _ from 'lodash';
import { returnCourseApiName } from './services/getcourse';
export const getDomain = () => {
  const domain = `${window.location.protocol}//${window.location.host}`;
  return domain;
};

const DOMAIN = getDomain() || 'http://localhost:3000';

export const ALMABETTER_HOME_LINKS = {
  ROOT: 'https://www.almabetter.com',
  get TERM_AND_CONDITIONS() {
    return `${this.ROOT}/terms-of-use`;
  },
  get PRIVACY() {
    return `${this.ROOT}/privacy-policy`;
  },
  get ABOUT_US() {
    return `${this.ROOT}/about-us`;
  },
  get COMMUNITY() {
    return `${this.ROOT}/community`;
  },
};

export const THINKIFIC_ALMABETTER_LINKS = {
  ROOT: 'https://learn.almabetter.com',
  get DATA_SCIENCE_PRO_LIVE_ALMAX_PRE_REG_FORM() {
    return `${this.ROOT}/courses/take/pro-live-onboarding/multimedia/27676716-almax-pre-registration-form`;
  },
  get DATA_SCIENCE_PRO_FLEX_ALMAX_PRE_REG_FORM() {
    return `${this.ROOT}/courses/take/pro-flex-onboarding/multimedia/27488097-almax-pre-registration-form`;
  },
  get WEB3_PRO_LIVE_ALMAX_PRE_REG_FORM() {
    return `${this.ROOT}/courses/take/web-development-pro-program-onboarding/multimedia/33533152-almax-pre-registration-form`;
  },
  get ENROLLMENTS_PAGE() {
    return `${this.ROOT}/enrollments`;
  },
  get SSO_LOGOUT_URL() {
    return `${this.ROOT}/users/sign_out`;
  },
};

export const IMAGE_FILE_SIZE_MAX = 1449616;

export const THINKIFIC_CONSTANTS = {
  SSO_LOGIN: {
    URL: 'https://almabetter.thinkific.com/api/sso/v2/sso/jwt',
    ERROR_URL: `${DOMAIN}/thinkific_login_error`,
  },
};

export const LOCAL_STORAGE_KEYS = {
  REDIRECT_TO: 'REDIRECT_TO',
  LOGIN_PROCESS_STAGE: 'LOGIN_PROCESS_STAGE',
  LOGOUT_PROCESS_STAGE: 'LOGOUT_PROCESS_STAGE',
  COURSE_INTERESTS: 'COURSE_INTERESTS',
  REFERRAL_CODE: 'REFERRAL_CODE',
  REFERRAL_GOAL: 'REFERRAL_GOAL',
  REFERRAL_PLAN: 'REFERRAL_PLAN',
  LINKEDIN_SHARE: 'LINKEDIN_SHARE',
  REFERRAL_SOURCE: 'REFERRAL_SOURCE',
  JOB_DASHBOARD_VIEWED: 'JOB_DASHBOARD_VIEWED',
  GLOBAL_SETTINGS: 'GLOBAL_SETTINGS',
  CAMPAIGN_PARAMS: 'CAMPAIGN_PARAMS',
};

export const LOGIN_LOGOUT_PROCESS_STAGE_VALUES = {
  COGNITO_AUTH_SUCCESSFUL: 'COGNITO_AUTH_SUCCESSFUL',
  LOGOUT_STARTED: 'LOGOUT_STARTED',
};

export const ASSIGNMENT_SUBMISSION_INSTRUCTION_VIDEO_URL =
  'https://ab-public-bucket-dev.s3.ap-south-1.amazonaws.com/submissionPart1.mp4';
export const CAPSTONE_PROJECT_SUBMISSION_INSTRUCTION_VIDEO_URL =
  'https://ab-public-bucket-dev.s3.ap-south-1.amazonaws.com/capstoneProjectVideo.mp4';

export const COURSES_OFFERED = ['data-science', 'wev-dev'];

export const RAZORPAY = {
  SCRIPT_SRC: 'https://checkout.razorpay.com/v1/checkout.js',
  KEY_ID: process.env.REACT_APP_RAZORPAY_KEY_ID || 'rzp_test_gVz50FqbpOF707',
  CURRENCY: 'INR',
};

export const ALMABETTER_LOGO_URL =
  'https://ab-public-bucket-prod.s3.ap-south-1.amazonaws.com/razorpay_icon.png';

export const PLAN_TYPE = {
  PRO_FLEX: 'pro_flex',
  PRO_LIVE: 'pro_live',
  PRO_LEGACY: 'pro_legacy',
  PRO_UPGRADE: 'pro_upgrade',
  NEW_USER: 'new_user',
  PREMIUM: 'premium',
  CORPORATE: 'corporate_training',
  MASTER: 'masters',
};

export const PLAN_TYPE_NAMES_MAPPING = {
  [PLAN_TYPE.PRO_FLEX]: 'Pro Flex',
  [PLAN_TYPE.PRO_LIVE]: 'Pro Live',
  [PLAN_TYPE.PRO_LEGACY]: 'Pro User',
  [PLAN_TYPE.NEW_USER]: 'New User',
  [PLAN_TYPE.PREMIUM]: 'Premium',
  [PLAN_TYPE.MASTER]: 'Masters',
};

export const COURSE_TYPE = {
  DATA_SCIENCE: 'data-science',
  WEB_DEV: 'web-dev',
  DATA_ENGINEERING: 'data-engineering',
  DS_BOOTCAMP: 'ds-bootcamp',
  MASTERS_DS_AS: 'masters-ds',
  MASTERS_CS: 'masters-cs',
  UG_DIPLOMA_CS: 'ug-diploma-cs',
  WEB3: 'web3',
};

export const COURSE_TYPE_NAMES_MAPPING = {
  [COURSE_TYPE.DATA_SCIENCE]: 'Certification in Full Stack Data Science and AI',
  [COURSE_TYPE.WEB_DEV]: 'Certification in Full Stack Web Development',
  [COURSE_TYPE.DS_BOOTCAMP]: 'Data Science Ninja Bootcamp',
  [COURSE_TYPE.DATA_ENGINEERING]: 'Full Stack Data Engineering',
  [COURSE_TYPE.MASTERS_DS_AS]: 'Masters in CS : Artificial Intelligence and Machine Learning',
  [COURSE_TYPE.MASTERS_CS]: 'Masters in Computer Science : Software Engineering',
  [COURSE_TYPE.UG_DIPLOMA_CS]: 'UG Diploma in Computer Science',
};

export const BOOKING_STAGE = {
  CREATED: 'created',
  TIME_SLOTS_ADDED: 'timeSlotsAdded',
  COACH_ASSIGNED: 'coachAssigned',
  SESSION_CONDUCTED: 'sessionConducted',
  FEEDBACK_SUBMITTED: 'feedbackSubmitted',
  ADMIN_ON_HOLD: 'adminOnHold',
  ADMIN_APPROVED: 'adminApproved',
  CANCELLED: 'cancelled',
};

export const BOOKING_STATUS = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  DEFAULTED: 'defaulted',
};

export const USER_CREDITS_DEDUCTION_AMOUNT = 15;
export const REACT_APP_BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || 'https://strapiv2.almagrow.com/api/';

export const COMMUNITY = 'https://community.almabetter.com/';
export const DATE_FORMAT_1 = 'Do MMM YYYY, hh:mm A';
export const DATE_ONLY_FROMAT_1 = 'Do MMM YYYY';
export const DATE_ONLY_FROMAT_2 = 'dddd, DD MMM YYYY';

export const MENTORSHIP_RESOURCE_LINK =
  'https://almabetter.notion.site/Mentorship-65a81629919b4ee1b26efaa393d6851c';

export const FRESHDESK_SUPPORT_URL =
  'https://almabetter.myfreshworks.com/login/auth/1662641230821?client_id=451980303908285122&redirect_uri=https%3A%2F%2Falmabetter-support.freshdesk.com%2Ffreshid%2Fcustomer_authorize_callback%3Fhd%3Dsupport.almabetter.com';

export const PAYMENT_ONBOARDING_FORM_STAGE = {
  BASIC_INFO: 'BASIC_INFO',
  EDUCATION: 'EDUCATION',
  WORK_EXPERIENCE: 'WORK_EXPERIENCE',
  SOCIAL: 'SOCIAL',
  SKILLS: 'SKILLS',
  GOAL: 'GOAL',
};

export const UG_PAYMENT_ONBOARDING_FORM_STAGE = {
  BASIC_INFO: 'BASIC_INFO',
  TWELTH_EDUCATION: '12_EDUCATION',
  GRADUATION_EDUCATION: 'GRADUATION_EDUCATION',
  GOAL: 'GOAL',
};

const COURSE_PATHS = {
  FULL_STACK_DATA_SCIENCE: 'full-stack-data-science',
  FULL_STACK_WEB_DEVELOPMENT_WITH_WEB3: 'full-stack-web-development-with-web3',
  DS_BOOTCAMP: 'ds-bootcamp',
  ALMAX_THE_LAST_MILE: 'almax-the-last-mile-new',
  DATA_ENGINEERING: 'full-stack-data-engineering',
  MASTERS_DS_AS: 'masters-ds',
  MASTERS_CS: 'masters-cs',
  UG_DIPLOMA_CS: 'ug-diploma-cs',
};
export const COURSE_TYPE_COURSE_PATH_MAPPING = {
  [COURSE_TYPE.DATA_SCIENCE]: COURSE_PATHS.FULL_STACK_DATA_SCIENCE,
  [COURSE_TYPE.WEB_DEV]: COURSE_PATHS.FULL_STACK_WEB_DEVELOPMENT_WITH_WEB3,
  [COURSE_TYPE.DS_BOOTCAMP]: COURSE_PATHS.DS_BOOTCAMP,
  [COURSE_TYPE.DATA_ENGINEERING]: COURSE_PATHS.DATA_ENGINEERING,
  [COURSE_TYPE.MASTERS_DS_AS]: COURSE_PATHS.MASTERS_DS_AS,
  [COURSE_TYPE.MASTERS_CS]: COURSE_PATHS.MASTERS_CS,
  [COURSE_TYPE.UG_DIPLOMA_CS]: COURSE_PATHS.UG_DIPLOMA_CS,
};

export const COURSE_PATH_COURSE_TYPE_MAPPING = _.invert(COURSE_TYPE_COURSE_PATH_MAPPING);
export const getCriteriaForGivenCoursePath = (coursePath) => {
  if (COURSE_PATH_COURSE_TYPE_MAPPING[coursePath]) {
    const courseType = COURSE_PATH_COURSE_TYPE_MAPPING[coursePath];
    return { courseType };
  } else if (coursePath === COURSE_PATHS.ALMAX_THE_LAST_MILE) {
    return { courseType: COURSE_TYPE.DATA_SCIENCE, almax: true };
  }
  return {};
};
export const WEB_SOCKETS_ENDPOINT =
  process.env.REACT_APP_WEB_SOCKETS_ENDPOINT ||
  'wss://7nq4wvs58h.execute-api.ap-south-1.amazonaws.com/dev';

export const DISCORD_AUTH_CLIENT_ID =
  process.env.REACT_APP_DISCORD_AUTH_CLIENT_ID || '1098890425391132684';

export const COMMUNITY_EVENT_TYPES = {
  PAYMENT: 'payment',
  PLACEMENT: 'placement',
  ROUND_ASSIGNED: 'round-assigned',
  COURSE_COMPLETION: 'course-completion',
  PROJECT_COMPLETED: 'project-completed',
  ASSESSMENT_COMPLETED: 'assessment-completed',
  MODULE_COMPLETED: 'module-completed',
};

export const COURSE_SELECTOR = {
  certification: [
    {
      name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.DATA_SCIENCE],
      value: COURSE_TYPE.DATA_SCIENCE,
      planType: PLAN_TYPE.PRO_LIVE,
      courseType: COURSE_TYPE.DATA_SCIENCE,
      duration: '6 months',
      type: 'Part-time',
      classType: 'Online live classes',
      accredited: {
        woolf: false,
        iitGuwahati: true,
      },
      for: 'College students, graduates & working professionals.',
      outcome: '5-25 LPA Job + Certification',
    },
    {
      name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.WEB_DEV],
      value: returnCourseApiName(COURSE_TYPE.WEB_DEV),
      planType: PLAN_TYPE.PRO_LIVE,
      courseType: COURSE_TYPE.WEB_DEV,
      duration: '6 months',
      type: 'Part-time',
      classType: 'Online live classes',
      accredited: {
        woolf: false,
        iitGuwahati: true,
      },
      for: 'College students, graduates & working professionals.',
      outcome: '5-25 LPA Job + Certification',
    },
  ],
  masters: [
    {
      name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.MASTERS_DS_AS],
      value: COURSE_TYPE.DATA_SCIENCE,
      planType: PLAN_TYPE.MASTER,
      courseType: COURSE_TYPE.MASTERS_DS_AS,
      duration: '12 months',
      type: 'Part-time',
      classType: 'Online live classes',
      accredited: {
        woolf: true,
        iitGuwahati: true,
      },
      for: 'Graduates & Working Professionals',
      outcome: `5-40 LPA Job + Master's Degree`,
    },
    {
      name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.MASTERS_CS],
      value: returnCourseApiName(COURSE_TYPE.WEB_DEV),
      planType: PLAN_TYPE.MASTER,
      courseType: COURSE_TYPE.MASTERS_CS,
      duration: '12 months',
      type: 'Part-time',
      classType: 'Online live classes',
      accredited: {
        woolf: true,
        iitGuwahati: true,
      },
      for: 'Graduates & Working Professionals',
      outcome: `5-40 LPA Job + Master's Degree`,
    },
  ],
  // ugCourses: [
  //   {
  //     name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.UG_DIPLOMA_CS],
  //     value: COURSE_TYPE.UG_DIPLOMA_CS,
  //     planType: PLAN_TYPE.PRO_LIVE,
  //     courseType: COURSE_TYPE.UG_DIPLOMA_CS,
  //     duration: '12 months',
  //     type: 'Part-time',
  //     classType: 'Online live classes',
  //     accredited: {
  //       woolf: true,
  //       iitGuwahati: true,
  //     },
  //     for: '1st, 2nd & 3rd Year College Students',
  //     outcome: `Internship + Diploma + 5+LPA Job`,
  //   },
  // ],
};

export const ENROLLED_IN = {
  CERTIFICATION: 'certification',
  MASTERS: 'masters',
  UG_DIPLOMA: 'ugCourses',
};

export const COURSE_INTEREST_COURSE_TYPE = {
  dataScienceInterest: COURSE_TYPE.DATA_SCIENCE,
  softDevInterest: COURSE_TYPE.WEB_DEV,
  mastersDSInterest: COURSE_TYPE.MASTERS_DS_AS,
  mastersCSInterest: COURSE_TYPE.MASTERS_CS,
  ugdiplomaCSInterest: COURSE_TYPE.WEB_DEV,
};

export const getGoalAndPlanBasedOnInterest = {
  [COURSE_TYPE.DATA_SCIENCE]: {
    goalType: COURSE_TYPE.DATA_SCIENCE,
    planType: PLAN_TYPE.PRO_LIVE,
  },
  [COURSE_TYPE.WEB_DEV]: {
    goalType: returnCourseApiName(COURSE_TYPE.WEB_DEV),
    planType: PLAN_TYPE.PRO_LIVE,
  },
  [COURSE_TYPE.MASTERS_DS_AS]: {
    goalType: COURSE_TYPE.DATA_SCIENCE,
    planType: PLAN_TYPE.MASTER,
  },
  [COURSE_TYPE.MASTERS_CS]: {
    goalType: returnCourseApiName(COURSE_TYPE.WEB_DEV),
    planType: PLAN_TYPE.MASTER,
  },
  [COURSE_TYPE.UG_DIPLOMA_CS]: {
    goalType: returnCourseApiName(COURSE_TYPE.WEB_DEV),
    planType: PLAN_TYPE.PRO_LIVE,
  },
};

export const LESSON_ACTIVITIES_IDS_FOR_REFINER = {
  Concept: 'e4645f10-41ba-11ee-9727-81770d4ea292',
  'Concept-Colab': 'e4645f10-41ba-11ee-9727-81770d4ea292',
  'Concept-Notion': 'e4645f10-41ba-11ee-9727-81770d4ea292',
  Video: '8aa794c0-4268-11ee-a2af-f9fae2984f65',
  Quiz: 'a89d84f0-426e-11ee-a2ca-9feca5aa8883',
  Code: '0a405710-4272-11ee-8ad9-99e125048ea3',
  Assignment: 'e4eca580-4272-11ee-aa24-fd4a05278182',
};

export const SUB_PLAN_TYPES = {
  PREMIUM_AB: 'premium_ab',
  ICONIC_IITG: 'iconic_iitg',
  ICONIC_WOOLF: 'iconic_woolf',
  GLOBAL_IITG_WOOLF: 'global_iitg_woolf',
};

export const SUB_PLAN_TYPES_MAPPING = {
  [SUB_PLAN_TYPES.PREMIUM_AB]: 'Premium',
  [SUB_PLAN_TYPES.ICONIC_IITG]: 'Iconic',
  [SUB_PLAN_TYPES.ICONIC_WOOLF]: 'Iconic',
  [SUB_PLAN_TYPES.GLOBAL_IITG_WOOLF]: 'Global',
};

export const EMI_PLANS_FOR_SUB_PLAN_TYPES = {
  [SUB_PLAN_TYPES.ICONIC_IITG]: '*Pay in easy EMIs starting at ₹ 3,333 per month',
  [SUB_PLAN_TYPES.ICONIC_WOOLF]: '*Pay in easy EMIs starting at ₹ 3,333 per month',
  [SUB_PLAN_TYPES.GLOBAL_IITG_WOOLF]: '*Pay in easy EMIs starting at ₹ 5,555 per month',
};

export const COURSE_TYPE_COURSE_NAME_MAPPING = {
  [COURSE_TYPE.DATA_SCIENCE]: 'Data Science',
  [COURSE_TYPE.WEB3]: 'Web Development',
  [COURSE_TYPE.UG_DIPLOMA_CS]: 'UG Diploma CS',
};

export const STUDENTS_ID = [
  '000febd2-7c05-4d53-87a9-936ed368d4ce',
  '006d5598-0396-44e8-88d9-885169061e4b',
  '01ea9f74-cefb-4cbd-bd8b-fb3c10b79729',
  '029e5f77-20ed-4738-a078-48a801db8c20',
  '0392e598-accb-4914-b482-0633aa585a7d',
  '0395d69b-15e8-456d-85e5-689f98f1d7b6',
  '044896dc-4ba9-48c9-b9c5-891f552b728b',
  '048bdefc-45e7-4c12-a706-bd706c8b66f9',
  '0496958e-7a59-493b-823d-35051f70b3e4',
  '050d8b78-c9cc-42b3-a859-db799e007d80',
  '0575fa26-01fc-4db8-8be6-4ed563fa3522',
  '077c5934-56b5-4e49-a4c3-c2aa12dad108',
  '07be0e81-f163-4bbd-a1a7-dda76e14fa10',
  '0953e186-a952-4751-a69b-c3641f69d230',
  '0991c178-1cb2-42d4-941a-ea358a9acedc',
  '0b6bff0d-a44a-40fd-9ac2-1cb08961d806',
  '0c6ec349-e612-4e12-b5df-cd71952abc39',
  '0c8d3931-587c-4dec-9574-dec62e6385c5',
  '0c9ba815-5bce-4c08-8f73-09a971ef8537',
  '0caa1668-e429-4ae0-aa84-c83da5aada40',
  '0cc02c36-fe73-42ae-8e01-e040ff25c4c7',
  '0f823b68-38e5-49d5-9de7-c75a13bbbd66',
  '0ff03d12-3776-4924-afc4-0a75f233a319',
  '109b1ef4-6dcc-4d72-9c93-8e88e6972b45',
  '13e45ff2-32ed-4ae4-a3ff-339c4929e55b',
  '1419b7cc-7cdf-4a32-90ec-26487f555cd1',
  '157345ec-eb24-4090-96ab-cb27e0bb1f54',
  '15927fcd-fb24-4a92-ac4e-615b5d1d75e1',
  '15cf8e51-bd84-4be0-b8ec-12a837dd5195',
  '16126eb9-e693-4afc-9aa4-15c8b756a11e',
  '18b9f6a9-5b56-4738-8b4e-86ac3d56fd63',
  '1a2e5e8b-9852-46f5-aa2c-3b6256333405',
  '1a9e6adb-993f-46eb-8f7f-8e35aedfeb17',
  '1c67214a-c416-4f70-8b54-b6de7ef08c81',
  '1c744a33-8f51-455c-a44a-f305fd252098',
  '1cff0b67-d7ff-4277-928f-18fce61f8404',
  '1d191e69-a926-49fa-bd42-5d15ba2df490',
  '1df5a58f-6d70-4cc0-b447-04f7d3ad4284',
  '1e731435-c242-4bd4-974f-3e6863d21235',
  '1eb5726f-7803-4fbf-9751-5de248267344',
  '1f571bc8-e336-4033-9060-35747a774a11',
  '1f9451eb-15d5-45f7-b4b5-52ee24d82445',
  '1fd6b8a3-baef-4170-9f1c-5acb4457f67c',
  '21e85fce-df1f-4ade-8fbf-b24eef794758',
  '222e4351-cab4-488d-8c1c-e0fddfb172fe',
  '22de443e-82c4-4cf4-a6b0-fd0fa78c5ea2',
  '235b98d2-5c75-45e6-a703-d1f0c56e1c19',
  '237a382d-7609-4190-927e-b95dc0b96a16',
  '23bf1ef5-c6de-4dd1-90d7-6656e9d590c0',
  '24cbae7e-fa86-4686-b5b5-2b9c11c7c25b',
  '24dad4c5-db25-411e-ac06-704cbae1047b',
  '26cce5f5-2f0b-4ca9-b4fd-f26fcde27109',
  '2a648530-1c8c-43aa-9337-7a3b4cb55b49',
  '2aa36a57-767e-4678-ab94-7d7eeef4af48',
  '2ad275c6-e057-4d59-90d7-6bc34bd26ff7',
  '2b19c3c0-f6c6-4837-aa00-bfd76ea760e3',
  '2b3932c5-a804-4ace-9e6d-7137c7953df2',
  '2bd666d6-5664-4f48-a9e6-13e9e1c5c4d5',
  '2c3af4fe-8000-4248-b9ff-81311d3aa2ff',
  '2c529d68-1465-4f8e-b350-2733c9f6bf6d',
  '2c83796e-777d-48bb-8cf0-418bdcdbb6fe',
  '2d184b80-37e0-40e7-ad47-521463a18c7e',
  '2d71870f-deb8-4783-a621-15f3b9d70a2a',
  '2eb9b8ae-7fc2-4481-a619-5d4b2ac77081',
  '2f24add7-1478-4877-a50f-d41833e132dc',
  '2fe9538c-e2eb-4420-83a2-d9aa4776c0df',
  '2fef734e-e79a-4abb-9a9a-b4792434cb75',
  '30e97b7e-c8e5-43e9-9ab7-9581f348e794',
  '318dbad3-f125-4c12-be1f-ac00e6648935',
  '321847d8-11f0-4e7e-8677-6b7c7d4c9442',
  '321e2def-26ad-4a9e-8e17-f0f2bc775969',
  '32b84801-80f3-453f-ad7c-4e7cb62168ec',
  '339e09a3-12c7-41fb-b527-eb0456eaee23',
  '351829c5-d2ec-46cb-8e31-ab66a7248e7c',
  '35848275-81f7-4ac2-a23f-e99e4dc40347',
  '35d525ad-5e6c-4ee2-b7de-b425e97b03d8',
  '36b6e3c1-cf0b-4752-b79f-b85223df4073',
  '36f9385c-69b1-4a96-99e2-5716d149aad8',
  '38e265c6-1cec-4eed-a40c-2ed71a4b73f1',
  '39521f92-9b55-4989-bd36-fb9b0c0a4dd7',
  '3a2d07f1-0047-4ad9-ae34-b862e78ae4e7',
  '3b5871a8-adf9-47d0-9d0a-01d80c065832',
  '3c1757da-56d1-44e0-86b7-de1cf6777ef7',
  '3ceffc5d-aec9-4d9e-9321-f3e14d1aa0ab',
  '3e9d9951-4e1a-4f9f-b228-0b415d04b72b',
  '3ea0038b-abae-4d5d-b3ce-f3e818ad5f4b',
  '3eff14e5-4b82-4138-8e2a-fede288e815c',
  '3fa100d9-f5f9-4553-be8d-208ec019459c',
  '406b4a17-ce1a-40d4-bcf0-44ce3bdaa781',
  '40a2bfb1-d865-43fd-8398-34d149a61e29',
  '40f8864b-ce2d-471e-b77c-77aee0defdf7',
  '4198bf1e-24cf-4c41-92ac-650dfae2fa1c',
  '421731c2-0df4-4c84-8e65-fa18cfe42081',
  '4217926f-5941-4e63-9774-084a4495ef94',
  '42d25b68-6cc4-4960-a7ce-110558ca2b66',
  '43353afd-d4d1-4ff1-972e-1e974de3857c',
  '4498287e-2b58-4465-8f2c-8fcbd28e8e6e',
  '470c60ce-c6c2-4bd4-8dc8-f3b193408442',
  '476f3148-cc03-401d-a973-88f98b0bfabe',
  '48da9081-bca3-41ac-bbb9-79fc81950d9b',
  '48eb13b3-0173-44cf-93fd-2a50d20bc792',
  '48f5d86e-fec9-4d2d-bfdc-11f095d11c36',
  '49d05154-4f33-42ca-9ea7-b3f30a2a78f0',
  '4adc5a5b-48aa-4bf0-a07e-93eb8e4e97b3',
  '4b41587d-02f7-439d-811e-a89dd623adee',
  '4b8b3217-ba3f-46d1-985e-4047f65990a4',
  '4c18343e-5202-4997-8304-d48dab624853',
  '4c834ff5-e828-4908-a1c9-1b046710fce3',
  '4cdfaaa1-397b-4554-a608-3d5ecde8bbf1',
  '4d145687-d5f3-4304-9a25-f914ba19dd68',
  '4d370a3a-308f-435c-9110-1f346a1b652e',
  '4fe19e4d-7187-4b15-b352-3e37a6cad34b',
  '4fe81a14-f249-4c02-a100-ff6a5007c0a8',
  '4ffb2777-ad82-4db0-a846-5e50527d8f74',
  '51a700ac-32ab-4378-8c04-641def0acc88',
  '51db3fac-d544-41fa-9a67-ad533f8d6343',
  '5344fc40-8d1b-4766-a2cf-f283415c6d4a',
  '54c0de10-c36a-4a0c-bc2b-0d1231432cc5',
  '553f91c9-03e3-4f58-957d-81b5fd3e1597',
  '555919ae-021c-422d-87c3-1130e79924d7',
  '557a607f-131f-476c-b6df-937f614d23fc',
  '56a89bb9-9b4d-41c4-ba88-47af93fdd0f7',
  '572af99f-124b-41f7-af1b-db2ed4066f8d',
  '57ab8f63-3e5c-47f2-b38e-a1eac7264855',
  '5820849d-a650-427b-8ac7-9e4b02fa454a',
  '5868f22a-6b2a-4025-b254-82f70309dbfb',
  '5879b87f-62d7-4efd-9d52-6879fb4b9f7b',
  '5a418f95-bcb7-4a8f-adc6-5e321d444c6c',
  '5abdb996-7366-452a-b062-8732c9213f5b',
  '5acf9d4d-63a1-493d-9584-8dd903b128b1',
  '5c2455bc-098d-4128-afa5-506948b18acd',
  '5c6b34de-4b26-4155-9dfa-5db298f87cb7',
  '5d41f06e-9780-4c3a-afd9-59feb13519eb',
  '5d710e6a-e29e-421a-8fe0-1c0cd25bc450',
  '5dc4fdd9-c154-4751-92de-93e95bca0b6a',
  '5df673df-f781-456e-bd6f-46c73b502497',
  '5f0d7b26-d49a-4657-8a60-757b68365964',
  '5fa72e02-0367-4579-a743-55a5bff67c4d',
  '6085fb62-2827-4a92-8898-083d3beabbd0',
  '6099ed55-d0b8-4e71-a656-36d7b302b234',
  '609df756-92ef-4bf2-b240-5583009e59e3',
  '60e05f81-5c15-4a52-94a4-86ba7a47ba1e',
  '62136ef7-a114-4c3b-9e59-1a49063040dd',
  '62827ef1-e804-4c6c-8526-4aaa298d109a',
  '6369eb7f-95b4-401c-8a43-fbdf0e153b86',
  '63773163-80b6-4fa4-bc98-afc0422261b8',
  '639d10e5-acec-45bd-a89a-d3fa626a5ec2',
  '6423c9fa-2d0c-43b6-a43e-48644bfaf589',
  '64f4e8d0-ec10-425d-8df9-e86c2ce5f759',
  '6552f2e3-7753-49b8-909f-bb677e57fb45',
  '65bcbf92-6561-45fd-a3f1-ca1b521a7d2e',
  '667ca6ce-1392-4c59-a614-a62f6be9ec70',
  '6904f324-276c-493d-8d9e-46b91cac0f2d',
  '69defee0-6c31-4566-989c-85828871072b',
  '6ba83eaf-ab21-43fd-93bc-76c9b47fd975',
  '6d48d7b3-0c5d-48be-bd82-9b499c304dd1',
  '6d64d8e5-7089-41f7-a37e-dcdeee9610a2',
  '6e0480ba-6bfd-4c91-9d88-0b1260cc3e56',
  '6fc9493c-fbc0-4d86-8777-7e60c18922dc',
  '70af7be5-bf1a-44ed-9a1d-2d6ec42662aa',
  '713ddff8-b9df-49fc-bef5-728845c202a6',
  '71e190fa-4439-406c-b4ca-e19c79b3c0b5',
  '72082a3f-a926-490a-a830-f9552a842174',
  '736ae480-d220-4c66-9be5-ab761b654cd3',
  '74fbd46d-5db0-4420-9e6c-bfe22392609f',
  '750a4891-d9b9-49d9-8811-c6c7f5bf553b',
  '766d3b2b-0a7a-4581-9d34-15dffd9da7b1',
  '76bd5ddd-1711-40f2-bb22-228f33861d37',
  '77ba2ec3-c59f-4f90-8a53-90417110e92d',
  '7859e4de-5a17-4693-bc90-e15f69d017d8',
  '79cb4b19-8410-4730-a92b-67a73cee6fae',
  '79d8a1da-a897-48c3-97e6-bd649f4ef05d',
  '7a500e34-29e7-4c6e-bf6e-a754aa387c3c',
  '7a51843b-1627-4a26-bde4-907d6aa36a25',
  '7aa80f65-9966-4023-9576-af8629edd752',
  '7b59030a-0484-4878-9bfe-487da606c840',
  '7c12ba71-a490-4c69-9eba-10fb57adf0a9',
  '7c2049a4-d5d0-4eaa-b14d-da1e9ff4b6e9',
  '7c46121d-cc87-400d-8f84-1bdabb5d8184',
  '7dfa1d26-d2d3-49aa-ac6e-ddd511d2d4da',
  '7dfbfcaf-3409-4226-bd48-837d943092b1',
  '7e07f236-2326-4749-a825-53b95c460918',
  '7e28a382-fd7f-42de-87a3-20f364b45d62',
  '7e78bfd3-b19d-4403-8f8f-1b8f40e0ea3b',
  '7eb6bd5e-1b57-4e37-b485-e8bb3112d7a4',
  '7f05db87-6f57-400b-a2f9-8fcf54e2ee3e',
  '802c73bd-d45c-468a-84f4-23f5077e0bb4',
  '8053218e-71f7-47dd-b320-5195693b3046',
  '80839e13-e8f0-430a-b8b7-f46c30892277',
  '81260192-5471-4404-9a71-52bd45cf0a46',
  '81e92088-7674-4f12-9f43-d2b9075df22a',
  '83d6aa14-cc5e-41f1-ad29-5af3fcadfde0',
  '84def573-bd9e-4d11-acc9-5c35c9080664',
  '84f9c731-66ac-4f4c-8883-7a392b619445',
  '84fa8d62-d715-4a94-93ab-8519956c8dae',
  '85010a4a-2a4e-49c7-a3b5-ba4e895f3f11',
  '85147de1-4551-45b9-916b-16571819b0b7',
  '855f63d6-cba7-4eaf-878a-cb22ccebdd28',
  '8682fc85-1233-4bde-a94e-282f864c85a3',
  '86c9be0a-dbb5-42d1-83c2-8847189748dc',
  '8774a55a-545d-4140-9aae-3c9701abcc3c',
  '8900fb7d-caee-4893-9fc4-db147b273b7b',
  '89a166bf-a028-4fb2-8aad-83f015852ce5',
  '8aec6186-accb-4e49-a443-22d3fc62b986',
  '8af5ce7b-50d8-4158-91a2-998defc72fde',
  '8d0aa5c2-f88b-4664-bb3d-31ed8a4bef07',
  '8d27fd5d-72be-484b-b1c4-c2a45463007b',
  '8d43174a-3ee9-4e11-b94b-627382530171',
  '8dedc642-aedc-46e3-a009-e2c13271d222',
  '8e8cd71c-bb35-4ca6-8429-ab0511bb9b36',
  '8e9d7d52-c7d0-4632-aeba-3e083072e841',
  '90980deb-120f-41fd-b0f5-bc2f386c85ab',
  '9118921f-e912-4cb7-9439-2c6a14ade322',
  '919cf91e-4814-4faf-8b64-b266cbc7d8a0',
  '921906a5-3e49-4746-a6ac-844b1066487a',
  '931ae78f-59dc-406d-8adc-7eaeb0310fc2',
  '934d3062-5cca-41c2-8736-9934becf4d21',
  '93978f78-54b7-4e64-9892-aa4174a757c1',
  '93a58432-80c6-4aaa-9804-88f4d57f08bb',
  '93aa1baf-945e-4b0b-93fa-16328be4f073',
  '93ae4b74-c7ff-4711-83d0-a79b65adc0a4',
  '93cab098-4f13-4a78-a92d-27fad86faef1',
  '94396d17-00b7-4c99-8b0d-1d2d8d28f684',
  '94c21a11-95a0-4c23-aeba-c4c86994a856',
  '94d2e520-fee6-4916-b693-452b1fa5d835',
  '94f47f61-5273-4fea-a61a-bb177a1f27d9',
  '9525dfaf-faae-43e1-9a80-cf2ab4f52d82',
  '9530e894-8362-4eb8-bdfb-8cd7a0ac3204',
  '9668ff25-7fb7-4410-8235-6a6515b96091',
  '969aa072-c1cd-4a17-bed2-91662e99dc15',
  '97cdf6bf-8d5e-4691-8742-f59580a0e01b',
  '9832a198-b978-449c-bd7b-588fb6e49660',
  '98733457-b096-4ecf-ac37-9b1103d094ec',
  '9878c2f6-398f-426a-911d-8ee1915caf5b',
  '98df23a4-7a68-4dae-8ba9-cfeabb18b441',
  '99b93126-dd3f-4977-b7fe-c6f39b765c9e',
  '9ab8b42f-d73d-4cd8-8d8a-a8fd5e07c75b',
  '9cf1be24-4854-48b2-b9e7-b9a37a9efaed',
  '9e88f0dc-d1af-4648-8f12-ca0a47cd8654',
  'a041f05f-3d7c-4351-9425-3f5b3d96b1aa',
  'a0aed35f-b147-4ebb-8c01-3f43bf15daa1',
  'a13af911-4904-4ad0-9ee8-804a8811be6e',
  'a2154ca3-93c5-47ef-9665-dc0c3c6851a9',
  'a3244281-87fd-4531-9d06-f8b771ea1487',
  'a5080417-b1a7-4a65-9148-fa6b16d073bf',
  'a5b24d46-7a33-4429-8189-fd778e0fe225',
  'a5b392a3-d0da-472a-a87d-f1a4cc24e90f',
  'a5f6f1aa-f13b-4239-bf5b-562b613e6b49',
  'a73925f2-848f-42f2-a283-968ca31dddec',
  'a766ef7b-0222-4795-9c3d-9aaf690fa58a',
  'a8242533-e73f-4847-9c33-c3bf1d0ded74',
  'a89bcc24-d9b7-4adc-a298-2a44717a63d5',
  'a93f84c8-08cf-4c01-8bf8-b5a7037af023',
  'a9afce01-5b05-4a88-a04c-1d1e062f6855',
  'aad7988d-b427-4bbe-bf63-c9d65e23adef',
  'ab4feb9b-189f-4805-8d74-9b9c0d7c5a88',
  'ab687c9f-fdf7-4b6b-91d9-6a3481ba433e',
  'acb750f4-74d1-4fd6-8655-cfe8c5d7a925',
  'ad898000-1a75-40e9-a09a-d46a6959cabb',
  'af3888d5-239b-40af-9c08-0e0b1fae6b63',
  'afe5270a-317e-46d5-8e84-aa8c33e03fe9',
  'afe961a8-cec6-4c6e-bb6c-a64b09635ad7',
  'b0e5c9ff-97d2-4d83-98c0-8dbcbc5a9960',
  'b103d017-ca5a-4b4c-9761-7f93e2d29944',
  'b12a99f5-ebf6-4864-9da5-e0a86bf1960d',
  'b12b4075-5a8e-4881-a705-9f1e79ace382',
  'b18a828b-d944-44d2-bd97-8781e624806a',
  'b23004c6-3d50-4b31-917c-34611a68face',
  'b2f172f0-158b-4701-b143-72daf11e170d',
  'b454fdac-11f1-4d2c-a635-29c6b778430f',
  'b5730ba5-af85-4164-851b-473d76adfe14',
  'b5fc1db3-393d-4e3d-b245-35c8f84ef055',
  'b69210c2-4a1a-42a2-9aaa-6eb8f926c90a',
  'b6b603fc-ae62-48e6-b9da-83a44d565b22',
  'b6ba7a30-7b25-4b8a-b0f2-d939390f7676',
  'b6fd1250-8655-4884-ac59-8e40023e5d1d',
  'b76479a7-a5e2-4e03-81ab-46a29331da63',
  'b8e98865-656f-4e68-be16-e4794a504e2f',
  'b91fff82-a0fd-4763-a044-22c917120dce',
  'bad38215-08e3-4ace-b236-dbf112c27b0c',
  'bad87e61-febf-4f49-90a4-343ad8dab6b0',
  'bc2a9f7b-6bb3-43c6-87b7-9ef3369a1ba2',
  'bc3e0d28-2eb3-443c-b373-b4fdffa4b2cf',
  'bcdf53e8-e0f4-49e8-8914-5d2cf6ecbc45',
  'bce9fe05-e560-46df-9bca-7717395bc30d',
  'bd8cf752-55fb-4391-89d1-dfeb7167d8af',
  'bdd2dd3b-63e9-438b-8dba-8c47f0d81d76',
  'be19e6a3-09f8-42ea-8bd3-68bf061cae7e',
  'bebc926c-5ac6-464b-981c-1625681cde0a',
  'c03e6084-c7a0-4f98-b3a3-d092fe0fd984',
  'c183a6ee-dd7a-4735-bafe-73247e767bb1',
  'c2542c78-779c-4774-81ce-8b947ee07692',
  'c2a2faf8-cb3d-4f48-807c-ac84655823f7',
  'c3b6304e-1854-4fc8-807a-021a3fbf7402',
  'c458cf61-02e1-4240-ac5e-2dc4d4a2ef5a',
  'c6c917c3-6491-4196-a483-046471df4eaf',
  'c7a492f0-57c2-413b-bf4b-045a26105fdf',
  'c8d9be6d-d884-4d08-8c5b-3e5d6af36725',
  'c9c8bbed-56eb-4227-9deb-7ae0715e786a',
  'c9dab287-953b-4071-bc82-bdc966a82e8e',
  'ca78b44a-4231-47ed-81dd-b374546a4187',
  'caa805ff-4b44-4369-b83c-dd38a8326379',
  'ccedf4b2-44eb-4c20-8a26-6c2f73d130fe',
  'cd97090d-85e0-41d6-b659-b1df866cd6c7',
  'ce164914-20c7-40d4-a03a-33e1e64e0e1f',
  'cee1f693-c3fc-4ed1-b0b0-06932d12766c',
  'cf82d901-e4b3-49a6-88d1-81b5d724e566',
  'cf8d49ec-fcf9-4f51-b182-2c213523211a',
  'd0517db5-bac5-4ebd-9426-2c70e97c2407',
  'd18af5ba-816f-43fe-8af4-02e7d4e6905d',
  'd23ab0d7-3231-49ab-9685-e8fe3145180e',
  'd2961f8b-17a2-4e5d-b4d0-348368581133',
  'd2f080bb-5a54-4e19-81de-c9072d4109e2',
  'd555b30c-91c7-47e6-a36c-0a8eb0977f18',
  'd58f6ae9-3c67-44bd-a127-3fdba669d650',
  'd671fc72-8a19-4d11-9b09-5c781b732478',
  'd765d4a6-469c-4274-8652-ba2897cfcf30',
  'd7e58007-c1d0-49f3-92b0-e83fc08a9703',
  'd91508e0-f263-4ed5-be16-b26c4c674c86',
  'd99d28a9-d7a0-41fb-9bda-5f95c89e123f',
  'dce4081e-111f-435f-a146-5ff0b184a727',
  'dcec2c8f-0b4f-4ff7-8dd2-57ece7270a96',
  'dd2eb41d-75a3-44cb-8c24-f294a0d3ad54',
  'dd37c6a1-05fb-4747-8634-df0fe2094895',
  'dd9e7e93-ce61-4f4a-8dae-775be04c181b',
  'de192e68-2295-4752-ad8e-cf18d147056a',
  'dee0a9c3-a3dd-4559-b11d-da8bd2555a15',
  'df2f82ab-8234-44f7-841a-2e1c68be4b79',
  'dfb8d36d-0e66-40b1-a4cb-94df5a8972c0',
  'e2a28a06-4d14-4bfd-8949-f134b7e83957',
  'e323e41b-2d76-48ab-90f5-553b4868e7ec',
  'e3a7164e-9c0e-4e69-9c47-bdeb9b52664c',
  'e3f666d5-fd3f-4cf1-b819-8b2102b823ca',
  'e508188e-ada1-4ae5-8ca5-0709c4d2b6e4',
  'e8c78c69-ea90-4bd5-82be-0c2e8bd0fd0b',
  'e9e78f83-4926-49d1-9c79-59e5f0dbc136',
  'eb6d3811-6c56-4237-b74c-77fb306186f8',
  'eb7044a4-7a7a-498b-afdd-fe61c9a3c29f',
  'eba3cab5-ff6c-4468-a0ab-33cd2d07dd25',
  'ebbd695a-1773-45d2-9946-11760452282f',
  'ecd17a8d-10d5-48ec-9959-9a5358f4a97a',
  'ed3871d3-f99b-4db0-a2d5-2469e2281e57',
  'ef6a9b86-e240-4b1d-880c-14400dd532eb',
  'ef758c3f-95a1-40a7-90b9-ae97868dfd35',
  'efa06d56-f1e4-4f95-b4a9-19fbdf80c802',
  'efbd4c74-e63d-48a6-8239-79f05d81769d',
  'f10c5f86-7d82-47dd-99b4-9ea9e49728da',
  'f2819c0f-6cbd-4ba1-bd27-39675d5eb33d',
  'f294546c-79bc-449e-b22b-f3d2c8cbb4a2',
  'f2969e4f-dccb-4b81-9ba1-013b837e40ac',
  'f2a2d153-5ef2-43fa-953d-376115005f40',
  'f33d7be9-c80b-435f-8bdc-64c2f4166ff9',
  'f3499381-a7f8-4ec0-825e-88699e4aee86',
  'f399e13f-9e3e-4c51-9f98-f78ecfa4e54f',
  'f4a57c3d-146c-44cd-905f-1979789bd835',
  'f537eced-9990-4e8c-9438-03bf18da744a',
  'f542a168-75bb-46b3-93dc-5ef42a82374d',
  'f6c83c05-3251-4a0c-ab3d-5fd25e76520a',
  'f7d58215-f67b-4bc2-b677-97ede542711c',
  'f7dd3bfe-8ded-402f-907d-03be75f50c43',
  'f7ea896d-a8cc-496e-a203-c21207808b93',
  'f82a18cb-e4a0-49a3-84a4-26810ba9a61f',
  'f9d0821f-a4dc-4c6d-a90c-9188e9bbf494',
  'fa8ab4ff-6207-4d5e-8ea5-680e8d4ec5cf',
  'fb0b6b2d-7cff-4d83-8a59-2a5617f5cf00',
  'fb16b880-606e-47f2-9bcd-daa6c118600a',
  'fbcb8621-c08b-421a-9926-0afda22e28ab',
  'fc2afb01-6aea-45ef-9668-aa52f55135cf',
  'fcdc5d11-44ec-47e0-bca3-e2b26a7509f9',
  'fe27b860-6a6f-4378-9e87-b32cf72d8a5f',
  'feb7ef1e-751f-4320-8bee-11aa5734eb59',
  'ff56a84d-9776-4259-9b53-fe00c1a3f1d8',
  'ff61210f-bbc0-4f54-a9d9-a5868d5368ad',
  'c537aaba-f3b5-444e-baa1-5c65b10ef2c1',
  '3d5fbcb2-2776-4eaa-9b1a-4fad13234983',
  '63b51dd2-3d45-48ea-bac2-aaf2c18c4800',
  '79fd5bbe-32f6-4003-a928-7db891232ce1',
  'fc4930ad-40b6-4999-b855-a227a42f769f',
  'b6eea4d2-ffe9-4793-9049-65f4229484db',
];
